@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.team-config {
    & > .d-flex > * {
        flex: 1 1 25%;
        margin-right: 5px;
    }

    .team-properties {
        display: flex;
        flex: 1 1 100%;
        flex-wrap: nowrap;

        & > :first-child {
            flex: 1 1 25%;
        }

        & > :nth-child(2) {
            flex: 1 1 75%;
        }
    }

    .MuiSelect-select {
        min-width: 100px;
    }

    .price-display {
        position: absolute;
        top: 1rem;
        right: 1rem;
        text-align: right;
    }

    .college-search .MuiInputBase-root {
        width: auto;
    }

    @include media-breakpoint-down(sm) {
        & > * {
            width: 100%;
        }

        .team-properties {
            flex-wrap: wrap;

            & > :first-child {
                flex: 1 1 100%;
            }

            & > :nth-child(2) {
                flex: 1 1 100%;
            }
        }
    }
}
