@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.app-container {
    .flyover {
        top: $app-chrome-header-height;
        bottom: $app-chrome-nav-height;

        &.bottom {
            top: $app-chrome-header-height;

            .buttons-bar {
                bottom: $app-chrome-nav-height;
            }
        }
    }
}

.flyover {
    position: fixed;
    width: 350px;
    top: 0;
    bottom: 0;
    transition: transform 0.3s linear;
    background: white;
    z-index: 899;
    overflow: auto;
    display: flex;
    flex-direction: column;

    &.left {
        left: 0;
        transform: translateX(-350px);
    }

    &.right {
        right: 0;
        transform: translateX(350px);
    }

    &.bottom {
        z-index: 99;
        width: 100%;
        top: $header-height;
        transform: translateY(100%);

        .buttons-bar {
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
        }
    }
}

.flyover-enter-active,
.flyover-enter-done,
.flyover-exit {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);

    &.left {
        transform: none;
    }

    &.right {
        transform: none;
    }

    &.bottom {
        transform: none;
    }
}

.flyover-enter,
.flyover-exit-active,
.flyover-exit-done {
    &.left {
        transform: translateX(-350px);
    }

    &.right {
        transform: translateX(350px);
    }

    &.bottom {
        transform: translateY(100%);
    }
}

@include media-breakpoint-down(md) {
    .flyover.bottom {
        top: $short-header-height;
    }
}

@include media-breakpoint-down(sm) {
    .flyover {
        width: 100%;
        max-width: unset;

        &.left {
            transform: translateX(-100%);
        }

        &.right {
            transform: translateX(100%);
        }

        &.bottom {
            transform: translateY(100%);
        }
    }

    .flyover-enter-active,
    .flyover-enter-done,
    .flyover-exit {
        &.left {
            transform: none;
        }

        &.right {
            transform: none;
        }

        &.bottom {
            transform: none;
        }
    }

    .flyover-enter,
    .flyover-exit-active,
    .flyover-exit-done {
        &.left {
            transform: translateX(-100%);
        }

        &.right {
            transform: translateX(100%);
        }

        &.bottom {
            transform: translateY(100%);
        }
    }
}
